import React from "react";

export default () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 96.62 90.82">
    <defs>
      <style>
        {`.cls-1{fill:none;}.cls-2{fill:url(#linear-gradient);}.cls-3{fill:url(#linear-gradient-2);}`}
      </style>
      <linearGradient
        id="linear-gradient"
        x1="48.31"
        y1="85.53"
        x2="48.31"
        y2="2.06"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#ea80fc" />
        <stop offset="0.16" stopColor="#e37dfc" />
        <stop offset="0.39" stopColor="#ce73fd" />
        <stop offset="0.67" stopColor="#ac63fe" />
        <stop offset="0.99" stopColor="#7e4eff" />
        <stop offset="1" stopColor="#7c4dff" />
      </linearGradient>
      <linearGradient
        id="linear-gradient-2"
        x1="0.79"
        y1="73.86"
        x2="94.32"
        y2="73.86"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0" stopColor="#3d5afe" />
        <stop offset="0.2" stopColor="#3e81fe" />
        <stop offset="0.5" stopColor="#40c4ff" />
        <stop offset="1" stopColor="#3d5afe" />
      </linearGradient>
    </defs>
    <g id="Layer_2" data-name="Layer 2">
      <g id="OBJECTS">
        <path
          className="cls-1"
          d="M43.19,32,28.82,56.91a5.91,5.91,0,0,0,5.12,8.87H62.68a5.92,5.92,0,0,0,5.13-8.87L53.44,32A5.91,5.91,0,0,0,43.19,32Z"
        />
        <polygon
          className="cls-1"
          points="53.44 32.02 67.81 56.91 67.81 56.91 53.44 32.02"
        />
        <polygon
          className="cls-1"
          points="28.82 56.91 28.82 56.91 43.19 32.02 28.82 56.91"
        />
        <path
          className="cls-2"
          d="M28.82,56.91,43.19,32a5.91,5.91,0,0,1,10.25,0L67.81,56.91h0L84.74,86.24A26.94,26.94,0,0,0,93,77.35a26.65,26.65,0,0,0,0-26.93L71.64,13.47a26.93,26.93,0,0,0-46.65,0l-21.34,37a26.65,26.65,0,0,0,0,26.93,26.94,26.94,0,0,0,8.23,8.89L28.82,56.91Z"
        />
        <path
          className="cls-3"
          d="M67.81,56.91a5.92,5.92,0,0,1-5.13,8.87H33.94a5.91,5.91,0,0,1-5.12-8.87L11.88,86.24A26.81,26.81,0,0,0,27,90.82H69.65a26.8,26.8,0,0,0,15.09-4.58Z"
        />
      </g>
    </g>
  </svg>
);
