import $ from './_Menu';
import debounce from 'lodash.debounce';
import React, { useEffect, useState } from 'react';
import Logo from './Logo';
import { useLocation, useHistory } from 'react-router-dom';
import { Link, Button } from '../../styles/components';
import { ArrowBackIos } from '@material-ui/icons';
import MenuItem from '@material-ui/core/MenuItem';
import LoadingIcon from './LoadingIcon';
import LoginAction from './LoginAction';
import SimpleMenuDropdown from '../../styles/components/Menu';
import { useGetAllStores } from '../../util/useGetAllStores';
//unsafe used for dev only
import icons from '../../icons';

export const links = {
  '/groups/create': ['Create group', 'Undecided1'],
  '/menu-list': ['Menu-list', 'Calendar'],
  '/search': ['Search', 'Search'],
  '/food/create': ['New', 'Plus'],
  '/events/add': ['Add event', 'Undecided2'],
  '/events': ['Events', 'Undecided4'],
  '/events/create': ['Create Event', 'Undecided3'],
  '/recap': ['Recap', 'Undecided2'],
};
const Menu = ({}) => {
  const { pathname } = useLocation();
  const history = useHistory();
  const root = pathname.substring(1);
  const [hidden, setHidden] = useState(false);
  const { store, historyPush, dispatch } = useGetAllStores();

  const {
    uid,
    user,
    historyStack,
    connection,
    media: { min768, max767 },
  } = store;

  //hide on scroll down, show on scroll up
  useEffect(() => {
    let currentPosition = 0;
    const handler = debounce(() => {
      if (currentPosition < window.scrollY) {
        //hide menu
        setHidden(true);
      } else if (currentPosition > window.scrollY) {
        //show menu;
        setHidden(false);
      }
      currentPosition = window.scrollY;
    }, 0);
    window.addEventListener('scroll', handler);
    return () => window.removeEventListener('scroll', handler);
  }, []);

  return (
    <>
      <$.Wrapper hidden={hidden}>
        {connection === 'offline' && <span>Offline</span>}
        {max767 && (
          <$.Row>
            <span>
              {historyStack.length > 0 && (
                <Button
                  onClick={() => {
                    history.goBack();
                  }}
                >
                  <ArrowBackIos />
                  &nbsp;
                  {historyStack[historyStack.length - 1].name}
                </Button>
              )}
            </span>
            <SimpleMenuDropdown id="mobile-menu">
              {Object.entries(links).map(([url, [name, iconName]], index) => {
                return (
                  <MenuItem key={index}>
                    <Link to={url}>{name}</Link>
                  </MenuItem>
                );
              })}
              <div>Version 1.1.31</div>
              <LoginAction />
            </SimpleMenuDropdown>
          </$.Row>
        )}
        {min768 && (
          <$.UserIcon>
            <img src={`${user?.photoURL?.replace(/=.+$/, '')}=s40`} alt="User" />
          </$.UserIcon>
        )}
      </$.Wrapper>
    </>
  );
};

export default Menu;
