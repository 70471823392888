import Hashids from 'hashids'
const hashids = new Hashids('dietgraph')

const toHex = (str: string): string => str.split("")
    .reduce((hex, c) => hex += c.charCodeAt(0).toString(16).padStart(2, "0"), "")
const toUTF8 = (num: string): string =>
    num.match(/.{1,2}/g)
        .reduce((acc, char) => acc + String.fromCharCode(parseInt(char, 16)), "");

//not meant to encrypt but obscure
// |
// |
// |
// V
const encrypt = (data: string) => {
    //encrypted ids are with '_'
    if (!data || data[0] === '_') return data;
    return '_' + hashids.encodeHex(toHex(data));
}


const decrypt = (data: string) => {
    //decrypted ids are without '_'
    if (!data || data[0] !== '_') return data;
    // first removing '_'
    return toUTF8(hashids.decodeHex(data.slice(1)))
}

export { encrypt, decrypt };
