import React from "react";
export default ({ fill = "#FDFEFF" }) => (
  <svg
    width="15"
    height="15"
    viewBox="0 0 10 15"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M9.49453 8.66103L1.54117 14.8135C0.866207 15.3351 0 14.7035 0 13.652V1.34706C0 0.297205 0.864957 -0.33604 1.54117 0.18722L9.49453 6.33969C9.64807 6.45655 9.7757 6.62546 9.86447 6.8293C9.95324 7.03314 10 7.26466 10 7.50036C10 7.73607 9.95324 7.96758 9.86447 8.17142C9.7757 8.37526 9.64807 8.54418 9.49453 8.66103Z"
      fill={fill}
    />
  </svg>
);
