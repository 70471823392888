import db, { firebase, functions } from "./firebase";
import addBarcodeToDatabase from "../util/fetch/barcode_write";
import { Endpoints } from "../types";
const {
  STATUS: { SUCCESS, NOT_FOUND, FETCHING, ERROR, NONE },
} = require("../constants");

const callbackWrapper = (functionsToCall) => ({
  dispatch,
  store,
  callback,
  time = new Date().setSeconds(0, 0).toString(),
  day = new Date().setHours(0, 0, 0, 0).toString(),
  ...props
}: Endpoints) => {
  if (!props.uid) return;
  dispatch({ type: "SET_STATUS", status: FETCHING });

  return Promise.all(functionsToCall({ time, day, ...props }))
    .then((result) => {
      dispatch({ type: "SET_STATUS", status: SUCCESS });
      callback?.();
    })
    .catch((err) => {
      console.log('firebase error', err)
      dispatch({ type: "SET_STATUS", status: ERROR });
    });
};

export const addFood = callbackWrapper(({
  saveBarcodeToDatabase,
  ...props
}: Endpoints) => {

  return [
    functions.httpsCallable("onAdd")({ ...props }),
    saveBarcodeToDatabase ? addBarcodeToDatabase(props.food[0] || props.food, props.uid) : null,
  ]
});

export const arbitraryFirebaseCall = callbackWrapper(({
  firebaseCalls,
  ...props
}: Endpoints) => {
  return firebaseCalls.map(fun => fun({ db, firebase, ...props }));
});

export const addEvent = callbackWrapper(({
  ...props
}: Endpoints) => {
  return [
    functions.httpsCallable("onAddEvent")({ ...props })
  ]
});
