import React from "react";
import $ from "./_LoginAction";
import { startLogin, startLogout } from "../../firebase/auth";
import { useLocation } from "react-router-dom";
import { Button } from "../../styles/components";
import MenuItem from "@material-ui/core/MenuItem";
import { useStore } from '../../util/contextStore';
import { IconButton } from '../../styles/components'
import { SignOut } from '../../icons'

export const LoginAction = () => {
    const [{ user, media: { min412, max411 } }] = useStore();
    const handelLogin = () => startLogin();
    const handelLogout = () => startLogout();
    return (
        <>
            {user ? (
                <>
                    {max411 ? <MenuItem><Button onClick={handelLogout}>Log Out</Button></MenuItem>
                        : <IconButton>
                            <SignOut />
                        </IconButton>
                    }
                </>
            ) : (
                <>
                    {max411 ? <MenuItem><Button onClick={handelLogin}>Log In</Button></MenuItem>
                        : <Button onClick={handelLogin}>Log In</Button>
                    }
                </>
            )}

        </>
    );
};

export default LoginAction;

// {
//     min412 && <$.UserIcon>
//         <img
//             src={`${user?.photoURL?.replace(/=.+$/, "")}=s40`}
//             alt="User"
//         />
//     </$.UserIcon>
// }