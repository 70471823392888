import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="currentColor"
    strokeWidth="2"
    class="ai ai-DragHorizontalFill"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M22 16a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 16a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M6 16a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M14 8a2 2 0 1 1-4 0 2 2 0 0 1 4 0z"
    />
  </svg>
);
