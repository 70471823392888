import styled, { keyframes } from "styled-components";
import { Column, Row } from "../../styles/reuseable";
import { screenBreakPoints } from "../../styles/global";
const fadeIn = keyframes`
 0% {
    opacity:0;
  }
  100% {
    opacity:1;
  }
`;

const Wrapper = styled(Column)`
  z-index: 10;
  height: 100vh;
  justify-content: space-between;
  padding: 10px 15px;
  position: fixed;
  transition: all 0.2s;
  top: 0;
  left: 0;
  display: none;

  @media (min-width: ${screenBreakPoints.sm}) {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: var(--sideMenuWidthShort);
  }

  @media (min-width: ${screenBreakPoints.lg}) {
    width: var(--sideMenuWidthLong);
  }
  .MuiButton-root {
    display: flex;
    justify-content: start;
  }
`;

const WordWrap = styled.span`
  display: none;
  white-space: nowrap;
  @media (min-width: ${screenBreakPoints.lg}) {
    display: inline;
  }
`;

export default { Wrapper, Column, Row, WordWrap };
