import React from 'react';
import styled from 'styled-components';
import { Link } from "../../styles/components";
import Logo from "../../icons/Logo";
const LogoWrapper = styled.span`
  height: var(--iconWidth);
  width: var(--iconWidth);
  margin-bottom:35px;
`;
export default ({ ...props }) =>
    <Link to={'/'} {...props}>
        <LogoWrapper>
            <Logo />
        </LogoWrapper>
    </Link>
    ;