import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ai ai-Box"
  >
    <rect x="3" y="3" width="18" height="18" rx="4" />
  </svg>
);
