import styled, { css } from "styled-components";
import { Row, Column, IconButton, centerImg } from "../../styles/reuseable";

const Wrapper = styled(Row)`
  height: 60px;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  padding: 10px 0;
  position: sticky;
  transition: all 0.2s;
  top: 0;
  ${({ hidden }) =>
    hidden
      ? css`
          top: -60px;
        `
      : css`
          top: 0;
        `};
`;

const UserIcon = styled.div`
  border-radius: 50%;
  height: 35px;
  width: 35px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  img {
    ${centerImg}
  }
`;
export default { Wrapper, Row, Column, IconButton, UserIcon };
