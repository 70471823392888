import React from "react";
export default ({ stroke = "#7F7D8C" }) => (
  <svg
    width="26"
    height="13"
    viewBox="0 0 26 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M24 8C18.0005 8 12.0134 7 6 7"
      stroke={stroke}
      strokeWidth="3"
      strokeLinecap="round"
    />
    <path
      d="M10.5714 2.0867C6.33983 1.65353 5.88373 2.85907 3.21694 5.35491C1.98227 6.51043 2.00001 6.73933 2.00001 8.52408C2.00001 10.7197 4.35376 10.5248 6.49736 10.5543C8.48462 10.5817 10.1976 11 12 11"
      stroke={stroke}
      strokeWidth="3"
      strokeLinecap="round"
    />
  </svg>
);
