export const STATUS = Object.freeze({
  NOT_FOUND: "NOT_FOUND",
  SUCCESS: "SUCCESS",
  FETCHING: "FETCHING",
  ERROR: "ERROR",
  NONE: "NONE"
});

export const VIEWS = Object.freeze({
  SEARCH_LIST: "SEARCH_LIST",
  NUTRITION: "NUTRITION",
  DEFAULT: "DEFAULT",
  FILTERS: "FILTERS",
  GROUP: "GROUP",
  CREATE: 'CREATE',
  EDIT: 'EDIT',
  PICK: "PICK",
  ADD: 'ADD',
});

export const ACTIVE_PAGE = Object.freeze({
  CHARTS: "CHARTS",
  ADD_FOOD: "ADD_FOOD",
});

export const MEDIA_MOBILE = window.matchMedia("(max-width: 481px)");

export const PAGINATION_LIMIT = 20;

export const NUTRITION_KEYS = [
  "ENERGY",
  "CARBS",
  "PROTEIN",
  "FAT",
  "SATURATES",
  "FIBRE",
  "SUGARS",
  "SALT",
];

const ERROR_RESPONSES = {
  DEFAULT: "Sorry, something went wrong...Try again later.",
  NETWORK_ERROR: "Might be a problem with you internet connection.",
}
