import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "../util/contextStore";

export const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [{ uid }] = useStore();

  return (
    <>
      <Route {...rest}>
        {uid ? (
          <Component />
        ) : (
          <Redirect
            to={{
              pathname: "/",
              from: location,
            }}
          />
        )}
      </Route>
    </>
  );
};

export default PrivateRoute;
