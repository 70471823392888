import _ from '@loadable/component';
export default {
'/add/food/:id': _(() => import('../_pages/add/food/id')),
'/add/group/:id': _(() => import('../_pages/add/group/id')),
'/events': _(() => import('../_pages/events')),
'/events/add': _(() => import('../_pages/events/add')),
'/events/create': _(() => import('../_pages/events/create')),
'/events/group/:id': _(() => import('../_pages/events/group/id')),
'/events/:id': _(() => import('../_pages/events/id')),
'/food/create': _(() => import('../_pages/food/create')),
'/food/:id': _(() => import('../_pages/food/id')),
'/groups/create': _(() => import('../_pages/groups/create')),
'/groups/:id': _(() => import('../_pages/groups/id')),
'/menu-list': _(() => import('../_pages/menu-list')),
'/recap': _(() => import('../_pages/recap')),
'/search': _(() => import('../_pages/search')),
}