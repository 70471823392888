import styled from "styled-components";
import { Row, Column } from "../../styles/reuseable";
import { screenBreakPoints } from "../../styles/global";
const Wrapper = styled.div`
  width: 100%;
  padding: 0 20px 50px 20px;
  @media (min-width: ${screenBreakPoints.md}) {
    margin-left: var(--sideMenuWidthShort);
  }
  @media (min-width: ${screenBreakPoints.lg}) {
    margin-left: var(--sideMenuWidthLong);
  }
`;

export default { Wrapper, Row, Column };
