import React from 'react';
import { getSearchParams } from './util';

const decodeOrigin = (search) => {
    return {
        ...search, ...search.origin
            ? { origin: decodeURIComponent(search.origin) } : {}
    }
}

export const getSearch = (history) => {
    const { location: { search } } = history;
    return search ? decodeOrigin(
        getSearchParams(search)
    ) : {};
}