import React, { useEffect, useState } from "react";
import { useStore } from "../../util/contextStore";
import $ from "./_Status";
const {
  STATUS: { SUCCESS, NOT_FOUND, FETCHING, ERROR, NONE },
} = require("../../constants");
const Status = ({}) => {
  const [{ status }, dispatch] = useStore();

  const [appStatus, setAppStatus] = useState(NONE);
  useEffect(() => {
    if (status === NONE) return;
    dispatch({ type: "SET_STATUS", status });
    const loadingHandler = setTimeout(() => {
      dispatch({ type: "SET_STATUS", status: NONE });
    }, 3000);
    return () => clearTimeout(loadingHandler);
  }, [status]);
  return (
    <>
      {appStatus === SUCCESS && (
        <>
          <$.Wrapper>
            <$.Alert>✔</$.Alert>
          </$.Wrapper>
        </>
      )}
      {appStatus === ERROR && (
        <>
          <$.Wrapper>
            <$.Alert>Something went wrong...</$.Alert>
          </$.Wrapper>
        </>
      )}
    </>
  );
};

export default Status;
