import { createGlobalStyle } from "styled-components";
import styledNormalize from "styled-normalize";
import { ThemeType } from './theme';


export const screenBreakPoints = {
  sm: '576px', // mobile
  md: '768px', // tablet
  lg: '992px', // laptop
  xl: '1200px', // pc
  xxl: '1400px'
};

export default createGlobalStyle<{ theme: ThemeType }>`
  ${styledNormalize}
  * {
    box-sizing: border-box;
  }
  html, body {
    height: 100%;
    width:100%;
 
    --sideMenuWidthLong: 160px;
    --sideMenuWidthShort: 60px;
    --spaceBetweenIcons: 15px;
    --gutter: 10px;
    --iconWidth:20px;
  }
  body {
    overflow-x: hidden;
    overflow-y: visible;
    font-size: 16px;
    line-height: 1.6;
    font-style: normal;
    min-height: 100%;
    color: ${({ theme }) => theme.secondaryText};
    font-family: ${({ theme }) => theme.font_family.secondary};   
    background-color: ${({ theme }) => theme.background};
    -webkit-font-smoothing: antialiased;
  }
  h1, h2, h3, h4, h5 {
    margin:0;

  }
  h1, h2 {
    font-family:${({ theme }) => theme.font_family.primary};
    color:${({ theme }) => theme.primaryText};
  }
  h3, h4, h5 {
    font-family:${({ theme }) => theme.font_family.secondary};
    color:${({ theme }) => theme.secondaryText};
  }
  h2{
   font-size: 24px;
   line-height: 29px;
   font-weight: normal;
  }
  h4 {
  font-size:18px;
  }
  a {
    text-decoration: none;
  }

  ul {
   list-style-type: none;
   padding: 0;
   margin: 0;
  }

  button {
   padding: 0;
   border: none;
   outline: none;
   font: inherit;
   background: none;
   cursor: pointer;
   text-align: center;
   text-decoration: none;
   transition: 0.1s ease-in-out;
   &:hover {
     opacity: 0.8;
    }
  }
  button:disabled {
   cursor: not-allowed;
  }
  button:focus {
   outline: 0;
  }
  textarea:focus, input:focus{
    outline: none;
  }
  #app {
    display:flex;
    flex-direction: row;
  }
`;
