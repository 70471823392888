// store.js
import React, { createContext, useContext, useReducer } from "react";
const StoreContext = createContext();

export const historyState = (() => {
  const state = {};
  const getState = () => state;
  const setState = (name, newState) => {
    state[name] = newState;
  };
  return { getState, setState };
})();

///media queries =>

export const queries = {
  max575: "(max-width: 575.98px)",
  min576: "(min-width: 576px)",

  max767: "(max-width: 767.98px)",
  min768: "(min-width: 768px)",

  max991: "(max-width: 991.98px)",
  min992: "(min-width: 992px)",

  max1199: "(max-width: 1199.98px)",
  min1200: "(min-width: 1200px)",

  max1399: "(max-width: 1399.98px)",
  min1400: "(min-width: 1400px)",
};

const getDefaultMediaQueries = () =>
  Object.entries(queries).reduce(
    (acc, [name, query]) => (
      (acc[name] = window.matchMedia(query).matches), acc
    ),
    {}
  );

/// <====

const applyMiddleware = (dispatch, getState) => (action) => {
  switch (action.type) {
    case "UPDATE_CART_PRODUCT":
      break;
  }
  dispatch(action);
};

const defaultFirebaseState = {
  timestamp: 0,
  events: {},
  data: {},
  event_status: {},
  food_ids: {},
  event_start: {},
  recent_servings: {},
};
export const defaultState = {
  connection: "online",
  firebase: defaultFirebaseState,
  user: null,
  uid: null,
  cash: {},
  tempFoods: {},
  notification: "",
  callback: null,
  nutritionData: {
    timeline: [],
    events: {},
    data: {},
    total: {},
  },
  macros: {},
  time: 0,
  status: "NONE",
  themeType: "dark",
  historyStack: [],
  currentPage: "",
  media: {},
};

const reducer = (state, action) => {
  switch (action.type) {
    case "MATCH_MEDIA":
      return {
        ...state,
        media: action.media,
      };
    case "SET_CONNECTION_STATUS":
      return {
        ...state,
        connection: action.value,
      };
    case "SET_THEME":
      return {
        ...state,
        themeType: action.themeType,
      };
    case "SET_CURRENT_PAGE_NAME":
      return {
        ...state,
        currentPage: action.name,
      };
    case "SET_USER":
      return {
        ...state,
        ...(action.user?.uid
          ? { user: action.user, uid: action.user.uid }
          : { user: null, uid: null, firebase: defaultFirebaseState }),
      };
    case "SET_STATUS":
      return {
        ...state,
        status: action.status,
      };
    case "HISTORY_GO_BACK":
      if (state.historyStack.length > 0) state.historyStack.length--;
      return {
        ...state,
        historyStack: [...state.historyStack],
      };
    case "ADD_TO_HISTORY_STACK":
      return {
        ...state,
        cash: { ...state.cash, ...action.cash },
        historyStack: [
          ...state.historyStack,
          { url: action.location, name: state.currentPage },
        ],
      };
    case "SET_FIREBASE":
      const newData = {
        ...state.firebase,
        ...action.data,
        timestamp: Date.now(),
      };
      return {
        ...state,
        firebase: newData,
      };
    case "SET_TIME":
      return {
        ...state,
        time: action.time,
      };
    case "SET_SCREEN_TYPE":
      return {
        ...state,
        screenType: action.screen,
      };
    case "SET_CALLBACK":
      return {
        ...state,
        callback: action.callback,
      };
    case "CALL_CALLBACK":
      state.callback?.();
      return {
        ...state,
        callback: null,
      };
    case "CLEAR_CALLBACK":
      return {
        ...state,
        callback: null,
      };
    case "SET_GROUP":
      return {
        ...state,
        group: {
          groupId: action?.groupId || state.group.groupId,
        },
      };
    case "SET_NOTIFICATION":
      return {
        ...state,
        notification: action.notification,
      };
    case "ADD_TEMP_FOOD":
      return {
        ...state,
        tempFoods: { ...state.tempFoods, [action.id]: action.food },
      };
    case "SET_SEARCH_RESULT":
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          local: { ...state.searchResult.local, ...(action?.local || {}) },
          remote: { ...state.searchResult.remote, ...(action?.remote || {}) },
        },
      };
    case "SET_SEARCH_FOOD_FILTER":
      return {
        ...state,
        searchResult: { ...state.searchResult, filter: action.filter },
      };
    case "ADD_NUTRITION_DATA":
      return {
        ...state,
        nutritionData: action.nutritionData,
      };
    case "ADD_MACROS":
      return {
        ...state,
        macros: action.macros,
      };
    case "SET_SEARCH_INPUT":
      return {
        ...state,
        searchResult: {
          ...state.searchResult,
          searchInput: action.searchInput,
        },
      };
    case "ADD_TO_MENU_VIEW_STACK":
      return {
        ...state,
        menuViewStack: [...state.menuViewStack, action.view],
      };
    case "REMOVE_FROM_MENU_VIEW_STACK":
      return {
        ...state,
        menuViewStack: [...state.menuViewStack.slice(0, -1)],
      };
    default:
      throw new Error(`Unhandled action type: ${action.type}`);
  }
};

export const StoreProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, {
    ...defaultState,
    media: getDefaultMediaQueries(),
  });

  const getState = () => state;

  const enhancedDispatch = applyMiddleware(dispatch, getState);

  return (
    <StoreContext.Provider value={[state, enhancedDispatch]}>
      {children}
    </StoreContext.Provider>
  );
};

export const useStore = () => useContext(StoreContext);
