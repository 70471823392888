import React from "react";
import $ from "./_LoadingIcon";
import { useStore } from '../../util/contextStore'
const {
  STATUS: { SUCCESS, NOT_FOUND, FETCHING, ERROR, NONE }
} = require("../../constants");

const LoadingIcon = () => {
  const [{ status }] = useStore();

  return (
    <>
      {status === FETCHING && <$.LoadingIcon><div></div><div></div></$.LoadingIcon>}
    </>
  );
};

export default LoadingIcon;
