import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";

import firebase from "firebase/app";

console.log(
  ">>>>>>process.env.FIREBASE_PROJECT_ID: " +
    process.env.FIREBASE_PROJECT_ID +
    "<<<<<<<<<<<"
);
const config = {
  apiKey: process.env.FIREBASE_API_KEY,
  authDomain: process.env.FIREBASE_AUTH_DOMAIN,
  databaseURL: process.env.FIREBASE_DATABASE_URL,
  projectId: process.env.FIREBASE_PROJECT_ID,
  storageBucket: process.env.FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.FIREBASE_MESSAGING_SENDER_ID,
};

firebase.initializeApp(config);
const database = firebase.firestore();
const functions = firebase.functions();
const googleAuthProvider = new firebase.auth.GoogleAuthProvider();
export { firebase, functions, googleAuthProvider, database as default };
