import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ai ai-AirplayAudio"
  >
    <path d="M12 16l6 5H6l6-5z" />
    <path d="M4 18a9.956 9.956 0 0 1-2-6C2 6.477 6.477 2 12 2s10 4.477 10 10a9.956 9.956 0 0 1-2 6" />
    <path d="M17.123 15.125a6 6 0 1 0-10.247-.002" />
    <path d="M14 12a2 2 0 1 0-4 0" />
  </svg>
);
