import anime from "animejs/lib/anime.es.js";
import React, { useEffect, useState } from "react";

const Animation = ({ children, enter, exit, active }) => {
  const [visible, setVisible] = useState(active);

  useEffect(() => {
    active
      ? setVisible(true)
      : anime({
          ...enter,
          ...exit,
          complete: function () {
            setVisible(false);
          },
        });
  }, [active]);

  useEffect(() => {
    if (visible) anime({ ...enter });
  }, [visible]);

  return <>{visible && children}</>;
};
export default Animation;
