import { match } from "xregexp/types";
import { SORTED, Portion, Map } from "../types";
import { decrypt, encrypt } from './unsafeEncrypt';
import shortid from 'shortid';
const pipe = (...args: any[]) => args.reduce((prev, curr) => curr(prev));

const encode = (str: string): string => {
  return (
    encodeURIComponent(str)
      // Note that although RFC3986 reserves "!", RFC5987 does not,
      // so we do not need to escape it
      .replace(/['()]/g, escape) // i.e., %27 %28 %29
      .replace(/\*/g, "%2A")
      // The following are not required for percent-encoding per RFC5987,
      // so we can allow for a little better readability over the wire: |`^
      .replace(/%(?:7C|60|5E)/g, unescape)
  );
};

const getPortion = ({ TEXT = "", AMOUNT }: Portion): string => {
  if (/\d+(\.\d+)?(\s)?(g|ml)/i.test(TEXT)) {
    return TEXT;
  } else if (AMOUNT > 0 && TEXT.length > 0) {
    return `${TEXT} (${AMOUNT}g/ml)`;
  } else if (AMOUNT > 0) {
    return `${AMOUNT}g/ml`;
  }
  return "";
};

const toUpperCase = (str: string) =>
  str.charAt(0).toUpperCase() + str.toLowerCase().substring(1);

const round = (value: number | string, decimals: number = 3): number => {
  return Number(Math.round(Number(value + "e" + decimals)) + "e-" + decimals);
};

const normalize = (data: SORTED): SORTED => {
  const { ENERGY, CARBS, PROTEIN, FAT, SATURATES, FIBRE, SUGARS, SALT } = data;
  const maxEnergy = Math.max(...ENERGY);
  const maxGrams = Math.max(
    ...CARBS,
    ...PROTEIN,
    ...FAT,
    ...SATURATES,
    ...FIBRE,
    ...SUGARS,
    ...SALT
  );
  return Object.keys(data).reduce((acc, key) => {
    acc[key] = data[key].map((ele: number | null) =>
      ele === null
        ? null
        : key === "ENERGY"
          ? round(ele / maxEnergy)
          : round(ele / maxGrams)
    );
    return acc;
  }, {} as SORTED);
};

const throttle = (fn: Function, time: number): Function => {
  let timeout;

  return function (...args: any[]) {
    const functionCall = () => fn.apply(this, args);
    clearTimeout(timeout);
    timeout = setTimeout(functionCall, time);
  };
};

const isThereACamera = async () => {
  if (navigator.mediaDevices || navigator.mediaDevices.enumerateDevices) {
    const devices = await navigator.mediaDevices.enumerateDevices();
    return devices.some((device) => device.kind === "videoinput");
  }
};

const isInvalidInput = (value) => {
  return /[`~!@#$%^*()_|+\=?;:'",.<>\{\}\[\]\\\/]/.test(value);
}

const isInvalidSearch = (value) => {
  return /[`~!@#$%^*()_|+\=?;:'",.<>\{\}\[\]\\\/]/.test(value);
}

const isBarcode = (value: string): boolean => {
  return /^[0-9]{3,55}$/.test(value);
}

//https://stackoverflow.com/questions/12317049/how-to-split-a-long-regular-expression-into-multiple-lines-in-javascript
const combineRegex = (regex = [], join = '|') => new RegExp(regex.map(r => r.source).join(join));
const matchCases =
  combineRegex([
    /(?<=food\/add\/)(\w+)/,
    /(?<=food\/edit\/)(\w+)/,
    /(?<=groups\/)(\w+)/,
    /(?<=group\=)(\w+)/,
  ]);

const testHash = (id: string): [boolean, string] => {
  if (id.length === 0) return [false, id];
  const idCandidate = decrypt(id);
  return [!/^event_|myFoods_|group_|fdcId_|[0-9]{3,50}/.test(idCandidate), idCandidate];
};

const getIdFromPath = (pathname) => {
  return pathname.match(matchCases)?.[0] || '';
}

const getSearchParams = (search: string) => {
  // return unsafeReplaceAllIds(search, 'decrypt')
  return search.slice(1).split('&').reduce((_acc, _el) => {
    const [key, val] = _el.split('=');
    if (key && val) _acc[key] = val;
    return _acc;
  }, {});
}

const unsafeReplaceAllIds = (data: string, action: 'decrypt' | 'encrypt') => {
  return data.replace(matchCases, (x) => action === 'encrypt' ? encrypt(x) : decrypt(x));
}

const swapIds = (args, action: 'decrypt' | 'encrypt') => {

  const [pathname, search] = [args.pathname, args.search || '']
    .map(_el => unsafeReplaceAllIds(_el, action));
  return { ...args, pathname, search };
}

const assembleSearch = (base: string = '', newQueries: Object) => {
  const extra = Object.entries(newQueries)
    .reduce((_acc, [_key, _el]) => ((_acc += `${_key}=${_el}`), _acc), '')
  return base ? `${base}&${extra}` : `?${extra}`
}

const editAbleFood = (id: string): boolean => {
  return /^(event_|myFoods_|group_|[0-9]{3,50})/.test(id);
}

const generateUniqueId = (key?: string): string => {
  const prefixes = {
    group: 'group_',
    event: 'event_',
    default: 'myFoods_',
  };
  return `${prefixes[key] || prefixes.default}${shortid.generate()}`
}

const withDefault = (target) => {
  const handler = {
    get: function (target, prop, receiver) {
      return target[prop] ? Reflect.get(...arguments) : target.DEFAULT;
    },
  };
  return new Proxy(target, handler);
}

const isFood = (id: string): boolean => {
  return /^(myFoods_|group_|fdcId_|[0-9]{3,50})/.test(id);
}

const getDays = ({ count, milliseconds }: { count?: number; milliseconds?: number; }): number[] => {
  const today = new Date().setHours(0, 0, 0, 0);
  const one_day = 24 * 60 * 60 * 1000;
  return Array(milliseconds ?
    (today - milliseconds) / one_day
    : count)
    .fill(0)
    .map((el, index) => today - index * one_day)
    .reverse();
}

export {
  pipe,
  encode,
  toUpperCase,
  getPortion,
  round,
  normalize,
  throttle,
  isThereACamera,
  isInvalidInput,
  isInvalidSearch,
  isBarcode,
  combineRegex,
  testHash,
  getIdFromPath,
  decrypt,
  encrypt,
  swapIds,
  getSearchParams,
  assembleSearch,
  editAbleFood,
  generateUniqueId,
  withDefault, isFood,
  getDays
};
