import styled, { keyframes } from "styled-components";

const animation = keyframes`
  0% {
    top: 18px;
    left: 18px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 36px;
    height: 36px;
    opacity: 0;
  }
`;
const LoadingIcon = styled.div`
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
  div {
    position: absolute;
    border: 4px solid #c9c6c6;
    opacity: 1;
    border-radius: 50%;
    animation: ${animation} 1s cubic-bezier(0, 0.2, 0.4, 1) infinite;
  }
  div:nth-child(2) {
    animation-delay: -0.5s;
  }
`;
export default { LoadingIcon };
