import React from 'react'
import styled from "styled-components";
import DefaultButton from "./Button";
import { useGetAllStores } from '../../util/useGetAllStores';
const StyledButton = styled(DefaultButton)`
&&:hover{
    background:none;
}
`;

export default ({ to, children, ...props }) => {
    const {
        historyPush,
    } = useGetAllStores();
    const handleClick = () => historyPush({ pathname: to }, { preserveSearch: false })
    return <StyledButton onClick={handleClick} {...props}>{children}</StyledButton>
}
