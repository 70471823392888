import React, { useEffect, useState } from "react";
import { useStore } from "./contextStore";
const isOnline = require("is-online");
export const useIsOnline = (name) => {
  const [navigatorStatus, setNavigatorStatus] = useState();
  const [_, dispatch] = useStore();
  useEffect(() => {
    // runs only on change, no initial value set
    const handleChange = ({ type }) => {
      setNavigatorStatus(type);
    };
    //set initial value
    // default is online so we are  setting offline only
    const setInitial = () => {
      if (!navigator?.onLine) {
        dispatch({
          type: "SET_CONNECTION_STATUS",
          value: "offline",
        });
      }
    };
    setInitial();
    window.addEventListener("offline", handleChange);
    window.addEventListener("online", handleChange);
    return () => {
      window.removeEventListener("offline", handleChange);
      window.removeEventListener("online", handleChange);
    };
  }, []);

  useEffect(() => {
    let intervalHandle = null;
    if (navigatorStatus === "offline") {
      //whelp
      dispatch({ type: "SET_CONNECTION_STATUS", value: navigatorStatus });
    }
    if (navigatorStatus === "online") {
      //check if really online
      //start polling until successful ping or offline event
      intervalHandle = setInterval(function () {
        isOnline().then((value) => {
          // navigatorStatus might change before promise is finished
          if (value && navigatorStatus === "online") {
            clearInterval(intervalHandle);
            dispatch({
              type: "SET_CONNECTION_STATUS",
              value: navigatorStatus,
            });
          }
        });
      }, 1000);
    }
    return () => {
      if (intervalHandle) clearInterval(intervalHandle);
    };
  }, [navigatorStatus]);
};
