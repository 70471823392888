import styled from "styled-components";
import { Column, Row } from "../../styles/reuseable";

const Wrapper = styled(Row)`
  position: absolute;
  z-index: 2000;
  padding: 0.75rem 1.25rem 0.75rem 2.25rem;
  border-radius: 0.25rem;
  left: -1000px;
  background: linear-gradient(to right, #f5f5ee00, rgb(209 236 241));
`;
export default { Column, Row, Wrapper };
