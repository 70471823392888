import React, { useEffect, useState } from "react";
import { useStore } from "../../util/contextStore";
import $ from "./_Notification";
import Animation from "../_App/Animation";
const Notification = ({}) => {
  const [state, setState] = useState(false);
  const [{ notification }] = useStore();

  useEffect(() => {
    if (state || !notification) return;
    setState(true);
    const loadingHandler = setTimeout(() => {
      setState(false);
    }, 3000);
    return () => clearTimeout(loadingHandler);
  }, [notification]);
  return (
    <>
      <Animation
        active={state}
        enter={{
          targets: "#notification_pop_up",
          duration: 300,
          translateX: 1000,
          easing: "easeInOutQuad",
        }}
        exit={{
          translateX: "0px",
        }}
      >
        <$.Wrapper id="notification_pop_up">{notification}</$.Wrapper>
      </Animation>
    </>
  );
};

export default Notification;
