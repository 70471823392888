import { firebase, googleAuthProvider } from "./firebase";
import isMobile from "../util/isMobile";

export const startLogin = () => {
  return isMobile()
    ? firebase.auth().signInWithRedirect(googleAuthProvider)
    : firebase.auth().signInWithPopup(googleAuthProvider);
};

export const startLogout = () => {
  return firebase.auth().signOut();
};
