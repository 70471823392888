import "react-dates/lib/css/_datepicker.css";
import AppRouter, { history } from "./routers/AppRouter";
import LoadingPage from "./components/LoadingPage";
import React from "react";
import ReactDOM from "react-dom";

import "./styles/fontawesome";

const jsx = <AppRouter />;

(async () => {
  if (process.env.NODE_ENV === "production") {
    await import("./util/initSentry");
  }
})();

ReactDOM.render(jsx, document.getElementById("app"));

// if ("serviceWorker" in navigator) {
//   window.addEventListener("load", () => {
//     navigator.serviceWorker.register("/sw.js");
//   });
// }

setInterval(() => {
  window._globalTime = Date.now();
}, 1000);
