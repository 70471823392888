import { Meal } from "../../../types";
import { pipe, encode, toUpperCase, getPortion, round } from "../../util";
import { authorization, url } from "./headers";
import { STATUS } from "../../../constants";
const { SUCCESS, ERROR } = STATUS;

const LANGUAGE = '_en';
export default async (food: Meal, id: string): Promise<string> => {
  const {
    NAME,
    BARCODE,
    INGREDIENTS,
    QUANTITY,
    SERVING_TEXT,
    ENERGY,
    CARBS,
    PROTEIN,
    FAT,
    SATURATES,
    FIBRE,
    SUGARS,
    SALT,
  } = food;
  const product_name = encode(NAME);

  const ingredients_text = encode(INGREDIENTS);

  //https://wiki.openfoodfacts.org/Data_fields#Serving_size
  const NOT_RECOMMENDED_serving_size_text_plus_g =
    SERVING_TEXT[0] ? pipe(SERVING_TEXT[0], getPortion, encode) : '';

  const nutriment_energy_in_KJ = round(ENERGY * 4.184, 0);

  const sendInfo = [
    `code=${BARCODE}`,
    `&user_id=${process.env.OPEN_FOOD_FACTS_ID}`,
    `&password=${process.env.OPEN_FOOD_FACTS_PASSWORD}`,
    NAME ? `&product_name${LANGUAGE}=${product_name}` : "",
    QUANTITY?.AMOUNT ? `&quantity=${QUANTITY.AMOUNT}g` : "",
    NOT_RECOMMENDED_serving_size_text_plus_g
      ? `&serving_size=${NOT_RECOMMENDED_serving_size_text_plus_g}` : "",
    ingredients_text ? `&ingredients_text${LANGUAGE}=${ingredients_text}` : "",
    ENERGY ? `&nutriment_energy=${nutriment_energy_in_KJ}` : "",
    CARBS ? `&nutriment_carbohydrates=${CARBS}` : "",
    PROTEIN ? `&nutriment_proteins=${PROTEIN}` : "",
    FAT ? `&nutriment_fat=${FAT}` : "",
    SATURATES ? `&nutriment_saturated-fat=${SATURATES}` : "",
    FIBRE ? `&nutriment_fiber=${FIBRE}` : "",
    SUGARS ? `&nutriment_sugars=${SUGARS}` : "",
    SALT ? `&nutriment_salt=${SALT}` : "",
  ];

  try {
    const promise = await fetch(
      `${url}/cgi/product_jqm2.pl?${sendInfo.join("")}`,
      {
        method: "GET",
        headers: {
          ...authorization,
          "User-Agent": `AsyncWay_App - ${navigator.userAgent} - UID ${id} - ${process.env.URL}`,
        },
      }
    );

    const result = await promise.json();
    return result.status === 1 ? SUCCESS : ERROR;
  } catch (error) {
    return ERROR;
  }
};
