import React, { useState, useEffect } from 'react';
import { useStore, queries } from './contextStore';

export const useMatchMedia = () => {
    const [_, dispatch] = useStore();
    const [mediaQueryLists] = useState(Object.entries(queries).
        map(([name, query]): [string, MediaQueryList] => [name, window.matchMedia(query as any)]));
    const getValue = () =>
        mediaQueryLists.reduce((acc, [name, mql]) => ((acc[name] = mql.matches), acc), {});
    useEffect(() => {
        const handler = () => {
            console.log('listener')
            dispatch({ type: 'MATCH_MEDIA', media: getValue() });
        }
        // Set a listener for each media query with above handler as callback.
        //initial check
        handler();
        //Safari support?
        mediaQueryLists.forEach(([_, mql]) => mql.addEventListener("change", handler));
        // Remove listeners on cleanup
        return () =>
            mediaQueryLists.forEach(([_, mql]) => mql.removeEventListener("change", handler));
    }, []);
}


// export const useMatchMedia = (queries) => {
//     const [mediaQueryLists] = useState(queries.map((q) => window.matchMedia(q)));
//     const getValue = () =>
//         mediaQueryLists.map((mql) => mql.matches);
//     const [state, setState] = useState(getValue())
//     useEffect(() => {
//         const handler = () => setState(getValue);
//         // Set a listener for each media query with above handler as callback.
//         mediaQueryLists.forEach((mql) => mql.addListener(handler));
//         // Remove listeners on cleanup
//         return () =>
//             mediaQueryLists.forEach((mql) => mql.removeListener(handler));
//     }, []);
//     return state;
// }
