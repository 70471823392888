import styled from "styled-components";
import { Row, Column, IconButton, centerImg } from "../../styles/reuseable";

const Wrapper = styled(Row)`
  width: 100%;
  justify-content: center;
`;

const Alert = styled(Row)`
  position: absolute;
  z-index: 2000;
  padding: 0.75rem 1.25rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  background-color: ${({ bgColor, theme }) => bgColor || "#d1ecf1"};
`;
export default { Wrapper, Row, Column, Alert };
