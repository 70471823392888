const breakpoints = {
  sm: 576, // mobile
  md: 768, // tablet
  lg: 992, // laptop
  xl: 1200, // pc
  xxl: 1400
}

const font_family = {
  primary: "'Roboto', sans-serif",
  secondary: "'Open Sans', sans-serif",
}
const Theme = {
  dark: {
    attention: "#171717",
    primaryText: "#171717",
    secondaryText: "#5E6162",
    gamma: "#C9CACA",
    background: "#E7EBED",
    detail: '#7C8284',
    font_family,
    breakpoints
  },
};
type ThemeType = typeof Theme.dark;
export { Theme as default, ThemeType };


// primary: "#fff",
//   secondary: "#070319",
//     attention: "#3026d2",
//       attentionSecond: "#D4CB21",
//         danger: "#E64131",
//           alpha: "#6B6680",
//             beta: "#8c93a6",
//               gamma: "#E5E4EA",
//                 delta: "#66D0B7",
//                   muiPrimary: "#3f51b5",
//                     epsilon: "rgba(0, 0, 0, 0.3)",
//                       eta: "",
//                         zeta: "",