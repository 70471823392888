import React from "react";
import $ from "./_SideMenu";
import { Link } from "../../styles/components";
import { links } from "./Menu";
import icons from "../../icons";
import Logo from "./Logo";
import { IconButton } from "../../styles/components";
import { SignOut } from "../../icons";
import { startLogout } from "../../firebase/auth";
const SideMenu = () => {
  return (
    <$.Wrapper>
      <$.Column
        css={`
          justify-content: space-between;
          height: 100%;
        `}
      >
        <div>
          <Logo />
          {Object.entries(links).map(([url, [name, iconName]], index) => {
            const Icon = icons[iconName];
            return (
              <Link key={index} to={url} startIcon={<Icon />}>
                <$.WordWrap>{name}</$.WordWrap>
              </Link>
            );
          })}
        </div>

        <Link to={null} onClick={() => startLogout()} startIcon={<SignOut />}>
          <$.WordWrap>Log Out</$.WordWrap>
        </Link>
      </$.Column>
    </$.Wrapper>
  );
};

export default SideMenu;
