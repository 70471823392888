import React from "react";

export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ai ai-CircleTriangleUp"
  >
    <circle cx="12" cy="12" r="10" />
    <path d="M12 8l-4 6h8l-4-6z" />
  </svg>
);
