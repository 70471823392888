import React from "react";
export default () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className="ai ai-Plus"
  >
    <path d="M12 20v-8m0 0V4m0 8h8m-8 0H4" />
  </svg>
);
