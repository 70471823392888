import styled, { css } from "styled-components";

const hidden = css`
  border: 0;
  clip: rect(0, 0, 0, 0);
  width: 2px;
  height: 2px;
  margin: -2px;
  overflow: hidden;
  padding: 0;
  position: absolute;
`;

const absoluteCenter = css`
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 100000;
`;

const overlay = css`
  content: "";
  display: block;
  background-color: rgba(0, 0, 0, 0.1);
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const centerImg = css`
  position: absolute;
  left: -9999px;
  right: -9999px;
  margin: auto;
  top: -9999px;
  bottom: -9999px;
`;

const PageWrapper = styled.div``;
const PageContent = styled.div``;

const Error = styled.p`
  color: #b90c0c;
  padding: 5px;
  background-color: #ffdcdc;
  border: 1px solid #b90c0c;
  text-align: center;
`;

const IconButton = styled.button`
  ${({ size }) =>
    size ? `height: ${size}px;width: ${size}px;` : "height: 22px;width: 22px;"};
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
`;

const Column = styled.div`
  display: flex;
  flex-direction: column;
`;
const Wrapper = styled.div`
  padding: 25px;
`;

const IconButtonWrapper = styled.button`
  padding: 10px;
  flex: 0 0 auto;
  display: flex;
`;

export {
  absoluteCenter,
  overlay,
  PageWrapper,
  PageContent,
  Error,
  IconButton,
  Row,
  Column,
  centerImg,
  hidden,
  Wrapper,
  IconButtonWrapper,
};
