import { Route, Router, Switch } from "react-router-dom";
import ThemeProvider from "../styles/ThemeProvider";
import NotFoundPage from "../components/_App/NotFoundPage";
import React, { useEffect, Suspense } from "react";
import Layout from "../components/_App/Layout";
import FrontPage from "../_pages";
import Login from "../components/_App/Login";
import { createBrowserHistory } from "history";
import theme from "../styles/theme";
import { StoreProvider } from "../util/contextStore";
import PublicRoute from "./PublicRoute";
import PrivateRoute from "./PrivateRoute";
import routes from "./routes";

export const history = createBrowserHistory();

const AppRouter = () => {
  useEffect(() => {
    //todo delay for preload?
    Object.values(routes).forEach(
      (component) => component?.preload && component.preload()
    );
  }, []);
  return (
    <Router history={history}>
      <StoreProvider>
        <ThemeProvider>
          <Layout>
            <Switch>
              <PublicRoute path="/" component={FrontPage} exact={true} />
              <PrivateRoute path="/login" component={Login} exact={true} />
              {Object.entries(routes).map(([path, component]) => {
                return (
                  <PrivateRoute
                    key={path}
                    path={path}
                    component={component}
                    exact={true}
                  />
                );
              })}
              <Route component={NotFoundPage} />
            </Switch>
          </Layout>
        </ThemeProvider>
      </StoreProvider>
    </Router>
  );
};

export default AppRouter;
