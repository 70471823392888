import React from "react";
import { Route, Redirect } from "react-router-dom";
import { useStore } from "../util/contextStore";
export const PublicRoute = ({ component: Component, location, ...rest }) => {
  const [{ uid }] = useStore();
  const { from = { pathname: "/search" } } = location;
  return (
    <>
      <Route {...rest}>
        {uid ? (
          <Redirect
            to={{
              ...from,
            }}
          />
        ) : (
          <Component />
        )}
      </Route>
    </>
  );
};

export default PublicRoute;
