import $ from "./_Layout";
import React, { useEffect, useState } from "react";
import GlobalStyle from "../../styles/global";
import Menu from "./Menu";
import SideMenu from "./SideMenu";
import Status from "./Status";
import { useStore } from "../../util/contextStore";
import Notification from "./Notification";
import db, { firebase } from "../../firebase/firebase";
import { useHistory } from "react-router-dom";
import { useIsOnline } from "../../util/useIsOnline";
import { useMatchMedia } from "../../util/useMatchMedia";
const { SUCCESS, FETCHING } = require("../../constants").STATUS;
const Layout = ({ children }) => {
  const [
    {
      uid,
      firebase: { timestamp },
      media: { min768 },
    },
    dispatch,
  ] = useStore();
  const history = useHistory();
  useIsOnline();
  useEffect(() => {
    const unsubscribe = history.listen((location) => {
      if (history.action === "POP") {
        dispatch({ type: "HISTORY_GO_BACK" });
      }
    });
    return () => unsubscribe();
  }, []);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged((user) => {
      dispatch({ type: "SET_USER", user });
    });
    return () => unsubscribe();
  }, []);

  //subscribe to changes
  useEffect(() => {
    if (!uid) return;

    const unsubscribe = db
      .collection("users")
      .doc(uid)
      .collection("data")
      .onSnapshot((querySnapshot) => {
        console.log("firebase sub call");
        let data = {};
        querySnapshot.docs.forEach((ele) => {
          const snap = ele.data();
          data = { ...data, [ele.id]: snap };
        });

        dispatch({ type: "SET_FIREBASE", data });
      });

    return () => unsubscribe();
  }, [uid]);

  useMatchMedia();
  return (
    <>
      {min768 && <SideMenu />}
      <$.Wrapper>
        <Menu />
        <Notification />
        {children}
      </$.Wrapper>
      <GlobalStyle />
    </>
  );
};

export default Layout;
