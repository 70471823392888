import React from "react";
export default ({ fill = "#9B9FA6" }) => (
  <svg
    width="25"
    height="25"
    viewBox="0 0 25 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1815 23.6074C21.0901 23.6074 21.9615 23.2465 22.604 22.604C23.2465 21.9615 23.6074 21.0901 23.6074 20.1815C23.6074 19.2729 23.2465 18.4015 22.604 17.759C21.9615 17.1166 21.0901 16.7556 20.1815 16.7556C19.2729 16.7556 18.4015 17.1166 17.759 17.759C17.1166 18.4015 16.7556 19.2729 16.7556 20.1815C16.7556 21.0901 17.1166 21.9615 17.759 22.604C18.4015 23.2465 19.2729 23.6074 20.1815 23.6074ZM20.1815 24.9778C21.4536 24.9778 22.6735 24.4725 23.573 23.573C24.4725 22.6735 24.9778 21.4536 24.9778 20.1815C24.9778 18.9095 24.4725 17.6895 23.573 16.79C22.6735 15.8906 21.4536 15.3853 20.1815 15.3853C18.9095 15.3853 17.6895 15.8906 16.79 16.79C15.8906 17.6895 15.3853 18.9095 15.3853 20.1815C15.3853 21.4536 15.8906 22.6735 16.79 23.573C17.6895 24.4725 18.9095 24.9778 20.1815 24.9778Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.1813 17.7832C20.363 17.7832 20.5373 17.8554 20.6658 17.9839C20.7943 18.1124 20.8665 18.2867 20.8665 18.4684V19.8977L21.3509 20.3821C21.4757 20.5113 21.5448 20.6844 21.5432 20.8641C21.5416 21.0437 21.4696 21.2156 21.3425 21.3426C21.2155 21.4696 21.0436 21.5417 20.864 21.5433C20.6843 21.5448 20.5113 21.4758 20.382 21.3509L19.4961 20.465V18.4684C19.4961 18.2867 19.5683 18.1124 19.6968 17.9839C19.8253 17.8554 19.9996 17.7832 20.1813 17.7832Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.79273 11.9592H4.42236V13.3296H5.79273V11.9592ZM4.42236 10.5889C4.05892 10.5889 3.71037 10.7332 3.45337 10.9902C3.19638 11.2472 3.052 11.5958 3.052 11.9592V13.3296C3.052 13.693 3.19638 14.0416 3.45337 14.2986C3.71037 14.5556 4.05892 14.7 4.42236 14.7H5.79273C6.15617 14.7 6.50473 14.5556 6.76172 14.2986C7.01871 14.0416 7.16309 13.693 7.16309 13.3296V11.9592C7.16309 11.5958 7.01871 11.2472 6.76172 10.9902C6.50473 10.7332 6.15617 10.5889 5.79273 10.5889H4.42236Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2739 11.9592H9.90357V13.3296H11.2739V11.9592ZM9.90357 10.5889C9.54012 10.5889 9.19157 10.7332 8.93457 10.9902C8.67758 11.2472 8.5332 11.5958 8.5332 11.9592V13.3296C8.5332 13.693 8.67758 14.0416 8.93457 14.2986C9.19157 14.5556 9.54012 14.7 9.90357 14.7H11.2739C11.6374 14.7 11.9859 14.5556 12.2429 14.2986C12.4999 14.0416 12.6443 13.693 12.6443 13.3296V11.9592C12.6443 11.5958 12.4999 11.2472 12.2429 10.9902C11.9859 10.7332 11.6374 10.5889 11.2739 10.5889H9.90357Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.7551 11.9592H15.3848V13.3296H16.7551V11.9592ZM15.3848 10.5889C15.0213 10.5889 14.6728 10.7332 14.4158 10.9902C14.1588 11.2472 14.0144 11.5958 14.0144 11.9592V13.3296C14.0144 13.693 14.1588 14.0416 14.4158 14.2986C14.6728 14.5556 15.0213 14.7 15.3848 14.7H16.7551C17.1186 14.7 17.4671 14.5556 17.7241 14.2986C17.9811 14.0416 18.1255 13.693 18.1255 13.3296V11.9592C18.1255 11.5958 17.9811 11.2472 17.7241 10.9902C17.4671 10.7332 17.1186 10.5889 16.7551 10.5889H15.3848Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M5.79273 17.4404H4.42236V18.8108H5.79273V17.4404ZM4.42236 16.0701C4.05892 16.0701 3.71037 16.2144 3.45337 16.4714C3.19638 16.7284 3.052 17.077 3.052 17.4404V18.8108C3.052 19.1742 3.19638 19.5228 3.45337 19.7798C3.71037 20.0368 4.05892 20.1812 4.42236 20.1812H5.79273C6.15617 20.1812 6.50473 20.0368 6.76172 19.7798C7.01871 19.5228 7.16309 19.1742 7.16309 18.8108V17.4404C7.16309 17.077 7.01871 16.7284 6.76172 16.4714C6.50473 16.2144 6.15617 16.0701 5.79273 16.0701H4.42236Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.2739 17.4404H9.90357V18.8108H11.2739V17.4404ZM9.90357 16.0701C9.54012 16.0701 9.19157 16.2144 8.93457 16.4714C8.67758 16.7284 8.5332 17.077 8.5332 17.4404V18.8108C8.5332 19.1742 8.67758 19.5228 8.93457 19.7798C9.19157 20.0368 9.54012 20.1812 9.90357 20.1812H11.2739C11.6374 20.1812 11.9859 20.0368 12.2429 19.7798C12.4999 19.5228 12.6443 19.1742 12.6443 18.8108V17.4404C12.6443 17.077 12.4999 16.7284 12.2429 16.4714C11.9859 16.2144 11.6374 16.0701 11.2739 16.0701H9.90357Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.6814 4.42224C1.6814 4.24052 1.75359 4.06624 1.88208 3.93775C2.01058 3.80925 2.18486 3.73706 2.36658 3.73706H5.79249V2.3667H2.36658C1.82142 2.3667 1.29858 2.58326 0.91309 2.96875C0.527601 3.35424 0.311035 3.87708 0.311035 4.42224V20.8666C0.311035 21.4118 0.527601 21.9346 0.91309 22.3201C1.29858 22.7056 1.82142 22.9221 2.36658 22.9221H16.2449C15.9527 22.5036 15.7295 22.041 15.5837 21.5518H2.36658C2.18486 21.5518 2.01058 21.4796 1.88208 21.3511C1.75359 21.2226 1.6814 21.0483 1.6814 20.8666V4.42224ZM19.4961 15.4331C19.9506 15.3681 20.412 15.3681 20.8665 15.4331V4.42224C20.8665 3.87708 20.6499 3.35424 20.2644 2.96875C19.8789 2.58326 19.3561 2.3667 18.8109 2.3667H16.7554V3.73706H18.8109C18.9927 3.73706 19.1669 3.80925 19.2954 3.93775C19.4239 4.06624 19.4961 4.24052 19.4961 4.42224V15.4331ZM7.16285 3.73706H14.4004V2.3667H7.16285V3.73706Z"
      fill={fill}
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M20.8662 8.53345H1.68115V7.16309H20.8662V8.53345Z"
      fill={fill}
    />
    <path
      d="M4.42188 0.996217C4.42188 0.814495 4.49406 0.640217 4.62256 0.51172C4.75106 0.383224 4.92533 0.311035 5.10706 0.311035C5.28878 0.311035 5.46306 0.383224 5.59155 0.51172C5.72005 0.640217 5.79224 0.814495 5.79224 0.996217V4.42212C5.79224 4.60385 5.72005 4.77812 5.59155 4.90662C5.46306 5.03512 5.28878 5.10731 5.10706 5.10731C4.92533 5.10731 4.75106 5.03512 4.62256 4.90662C4.49406 4.77812 4.42188 4.60385 4.42188 4.42212V0.996217Z"
      fill={fill}
    />
    <path
      d="M14.0144 0.996217C14.0144 0.814495 14.0866 0.640217 14.2151 0.51172C14.3436 0.383224 14.5179 0.311035 14.6996 0.311035C14.8813 0.311035 15.0556 0.383224 15.1841 0.51172C15.3126 0.640217 15.3848 0.814495 15.3848 0.996217V4.42212C15.3848 4.60385 15.3126 4.77812 15.1841 4.90662C15.0556 5.03512 14.8813 5.10731 14.6996 5.10731C14.5179 5.10731 14.3436 5.03512 14.2151 4.90662C14.0866 4.77812 14.0144 4.60385 14.0144 4.42212V0.996217Z"
      fill={fill}
    />
  </svg>
);
