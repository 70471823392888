import React, { useState, useEffect, useMemo } from 'react';
import { useStore, historyState } from './contextStore';
import { useHistory, useParams } from "react-router-dom";
import { Endpoints, ReduxState } from '../types';
import * as firebaseFunctions from '../firebase/writeFirebase';
import { getSearch } from '../util/getSearch';

type HistoryPushOptions = {
    origin?: boolean;
    preserveSearch?: boolean;
}

type OptionalProps = {
    [path: string]: any;
    name?: string;
    page?: boolean;
}
const defaultStateFunction = () => ({});
export const useGetAllStores = (defaultState: Function = defaultStateFunction, optionalProps: OptionalProps = {}) => {
    const history = useHistory();
    const params = useParams();
    const search = getSearch(history);
    const [store, dispatch] = useStore();
    const [state, setStateDefault] = useState(() => {
        return history.location.state?.[optionalProps.name] ??
            defaultState({ history, store, search, params, ...optionalProps });
    });

    useEffect(() => {
        let storeUpdated = false;
        if (optionalProps.page && store.currentPage !== optionalProps.name && !storeUpdated) {
            storeUpdated = true;
            dispatch({ type: "SET_CURRENT_PAGE_NAME", name: optionalProps.name })
        }
    }, [])

    const firebaseFunctionsWithInjectedParameters: any = Object.entries(firebaseFunctions).reduce((acc, [key, callback]) => {
        acc[key] = (args) => {
            callback({ ...args, uid: store.uid, store, dispatch })
        }
        return acc;
    }, {})

    const setState = (args) => {
        setStateDefault((prev) => {
            const newState = typeof args === 'function' ? args(prev) : args;
            historyState.setState(optionalProps.name, newState)
            return newState;
        })
    }
    const historyReplace = () => {
        history.replace({
            ...history.location,
            state: historyState.getState()
        });
    }

    const historyPush = (args, options: HistoryPushOptions = {}, cash = {}) => {
        dispatch({
            type: 'ADD_TO_HISTORY_STACK', cash,
            location: history.location.pathname
        });
        //writing state for current url
        historyReplace();
        history.push({
            ...args,
            search: updateSearch(args, options, history.location)
        });
    }


    return {
        state, setState: optionalProps.name ? setState : setStateDefault
        , store, dispatch, history, search,
        historyPush, historyReplace, params,
        firebaseFunctions: firebaseFunctionsWithInjectedParameters
    };
}

const encodeOrigin = (search, pathname) => {
    //throw away search string 
    const newOrigin = `origin=${encodeURIComponent(pathname)}`
    let replaced = false;
    const searchString = (search || '')
        //trying to catch existing origin
        .replace(/(origin=)([^&]+?)(&|$)/,
            (_, x, y, z) => {
                //means origin is already attached, we are replacing it with a new one
                replaced = true;
                return x + newOrigin + z;
            })
    return replaced
        //origin attached - return the string
        ? searchString

        : (searchString.length > 0 ? searchString + `&` : `?`) + newOrigin;
}
const updateSearch = (args, { origin = false, preserveSearch = true }, location) => {
    //args.search doesn't contain origin
    const { pathname, search = '' } = location;
    console.log('args', args);

    const newSearchString = preserveSearch && search ?
        // search + '&' + args.search
        search + (args.search ? '&' + args.search : '')
        :
        args.search ? '?' + args.search : ''

    return origin && newSearchString ? encodeOrigin(newSearchString, pathname) : newSearchString;
}